.image-container {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: zoom-in;
}

.image-container.zoomed img {
  transform: scale(2.25); /* Örnek bir büyütme oranı */
  transform-origin: center; /* Büyütme merkezi */
  transition: all 0.3s ease-in-out;
  cursor: zoom-out;
}

.zoomable-image.zoomed {
  background-size: 500% 500%; /* Fare pozisyonuna göre fotoğrafın büyüklüğü */
  cursor: zoom-in; /* Fareyi zoom-in şeklinde göster */
}